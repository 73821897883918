import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useTheme } from "styled-components"
import Html from "@components/html"
import Flex from "@components/elements/flex"
import Heading from "@components/elements/heading"
import Span from "@components/elements/span"
import Grid from "@components/elements/grid"
import Div from "@components/elements/div"
import Anchor from "@components/elements/anchor"
import { KlipsEOPCta } from "@components/cta/eop/klips"
import Image from "@components/image"
import Breadcrumb from "@components/blog/breadcrumb"

const PartnerTemplate = ({ data }) => {
  const partner = data.partner
  const { color } = useTheme()

  return (
    <Layout
      seo={{
        title: `${partner.name} Partners`,
        description: `Partner with ${partner.name} to help you succeed with data.`,
      }}
      hideOverflow
      marginless
      fullWidth
    >
      <Div
        background={color.blueLight}
        padding="10rem 0 5rem"
        width="100%"
        margin="0 auto 4rem"
        marginMd="0 auto 2rem"
      >
        <Image width={300} file={partner.logo} style={{ margin: "0 auto" }} />
      </Div>
      <Div margin="0rem auto 4rem" marginMd="0 auto" textContainer>
        <Breadcrumb
          noPadding
          links={[
            {
              text: "Partners",
              url: "/partners",
            },
            {
              text: partner.name,
            },
          ]}
        />
        <Heading margin="1rem 0 2rem">{partner.name}</Heading>
        <Flex gap="1rem">
          <Html parseElements html={partner.body} />
        </Flex>
      </Div>

      <Grid
        background={color.blueLight}
        padding="2rem"
        columns="repeat(3, 1fr)"
        columnsMd="1fr"
        gap="2rem"
        gapMd="1rem"
        borderRadius="1rem"
        borderRadiusMd="0"
        container
        width="100%"
        margin="0 auto 6rem"
        marginMd="0 auto 0"
      >
        <Div borderRadius="0.5rem" padding="2rem" background="white">
          <Heading as="h4" margin="0 0 .5rem">
            Country
          </Heading>
          <Span color={color.blue400} fontSize={"1.2rem"}>
            {partner.country}
          </Span>
        </Div>
        {partner.certified && (
          <Div borderRadius="0.5rem" padding="2rem" background="white">
            <Heading as="h4" margin="0 0 .5rem">
              Certified
            </Heading>
            <Span color={color.blue400} fontSize={"1.2rem"}>
              Yes
            </Span>
          </Div>
        )}
        {partner.global && (
          <Div borderRadius="0.5rem" padding="2rem" background="white">
            <Heading as="h4" margin="0 0 .5rem">
              Global
            </Heading>
            <Span color={color.blue400} fontSize={"1.2rem"}>
              Yes
            </Span>
          </Div>
        )}
        <Div borderRadius="0.5rem" padding="2rem" background="white">
          <Heading as="h4" margin="0 0 .5rem">
            Website
          </Heading>
          <Anchor
            color={color.blue400}
            link={partner.website}
            fontSize={"1.2rem"}
          >
            {partner.website}
          </Anchor>
        </Div>
        {partner.email && (
          <Div borderRadius="0.5rem" padding="2rem" background="white">
            <Heading as="h4" margin="0 0 .5rem">
              Email
            </Heading>
            <Anchor
              color={color.blue400}
              link={`mailto:${partner.email}`}
              fontSize={"1.2rem"}
            >
              {partner.email}
            </Anchor>
          </Div>
        )}
        {partner.phone && (
          <Div borderRadius="0.5rem" padding="2rem" background="white">
            <Heading as="h4" margin="0 0 .5rem">
              Phone
            </Heading>
            <Anchor
              color={color.blue400}
              link={`tel:${partner.phone}`}
              fontSize={"1.2rem"}
            >
              {partner.phone}
            </Anchor>
          </Div>
        )}
      </Grid>
      <KlipsEOPCta />
    </Layout>
  )
}

PartnerTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PartnerTemplate

export const pageQuery = graphql`
  query PartnerPageQuery($id: String!) {
    partner: partners(id: { eq: $id }) {
      body
      body_images {
        ...imageData
      }
      certified
      country
      email
      expertise
      global
      languages
      logo {
        ...imageData
      }
      name
      phone
      region
      summary
      website
    }
  }
`
